@import "../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
@import "../../../../scss/custom_tags.scss";

.image-gallery-thumbnail-image {
  max-height: 10vh;
  max-width: 100%;
  object-fit: contain;
}

$height: 40vh;

.image-gallery-slides {
  height: $height;
  max-width: 100%;
}

.image-gallery {
  align-items: center;
  align-self: center;
}

.image-gallery-slide {
  &.center {
    height: $height !important;
    background-color: white;
  }
  .image-gallery-thumbnail-image {
    max-width: 100%;
    max-height: $height;
    object-fit: contain;
  }
  .video-wrapper {
    height: $height;
    min-width: 40vw;
    .video-player {
      width: 100%;
    }
  }
}

@mixin generateHeight($breaker, $height) {
  @media (max-width: $breaker) {
    .image-gallery-slides {
      height: $height;
    }
    .image-gallery-slide {
      &.center {
        height: $height !important;
      }
      .image-gallery-thumbnail-image {
        
        max-height: $height;
      }
      .video-wrapper {
        height: $height;
      }
    }
  }
}

.image-gallery {
  align-items: center;
  align-self: center;
}


@include generateHeight($sm, 25vh);
