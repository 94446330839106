:root{
    --white: #fdfbff;
    --dark: #5c2c37;
    --light: #e2dad9;



    --primary: #2d3961;
    --secondary: #f8c2fc;
    --info: #eae7e9;

    --sucess: #1bc675;

    --warning: #ecd416;
    --danger: #fe2518;
}

$primary: #2d3961;
$secondary: #f8c2fc;;
$success:       #1bc675;
$info:          #eae7e9;
$warning:       #ecd416;
$danger:        #fe2518;
$light:         #e2dad9;
$dark:          #5c2c37;
$background: #f7f8fa;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);