@import "../../scss/colors";
@import "../../scss/custom_tags.scss";
.footer{
    background-color: $dark;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
    border-radius: 8px;
    padding: 20px 25px;
    a{
        color: $info;
        font-weight: lighter;
        border-radius: 3px;
        padding: 3px;
        text-decoration: none;
    }
    .footer_copyright{
        font-size: small;
        text-align: end;
        a{
            color: $info;
            font-weight: bolder;
            border-radius: 3px;
            padding: 3px;
            text-decoration: none;
        }
    }

    @media (max-width: $md) {
        display: block;
        .mail{
            font-size: smaller !important;
        }
        text-align: center;
        .footer_copyright{
            text-align: center;
        }
    }
}