@import "../../../../scss/colors";
.skill {
  height: 100%;
  text-align: center;
  .skill_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
    color: $primary;
  }

  .skill_description {
    color: $dark;
    border-color: $dark !important;
    margin-right: 5px !important;
    text-align: left;
  }

  .MuiSvgIcon-root {
    font-size: 46px;
    margin-left: 0;
    font-weight: 300;
    color: $dark;
  }
}
