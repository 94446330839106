
@import "../../../scss/colors";

.achievement {
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  
  .achievement_image{
    margin: auto;
    background: rgba(0,0,0,0);
  }
  .MuiSvgIcon-root {
    font-size: 46px;
    margin-left: 0;
    font-weight: 300;
    color: $dark;
    background-color: rgba($color: #000000, $alpha: 0);
  }
  .achievemnt_institution{
    font-weight: bolder;
  }
}
