@import "../../scss/colors";

.custom_item{
    &.active{
        color: $primary !important;
    }
}

.MuiTabs-indicator{
    background-color: $dark !important;
}   