@import "../../../../scss/colors";
@import "../../../../scss/custom_tags.scss";

.experience_item_title{
    font-size: 16px;
    font-weight: 600 !important;
}

// .experience_date{

// }

.experience_item_description{
    font-size: smaller !important;
    margin-top: 6px;
}